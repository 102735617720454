import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

import {
  type TENANT,
  type TenantLanguage,
  tenantToLocaleRegionMap,
} from '../../tenant'

export const crossSellAccessorGetLocalizedProductDeepLink = ({
  language,
  product,
  tenant,
}: {
  language: TenantLanguage
  product: CrossSellProduct
  tenant: TENANT
}): string => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- the rule was disabled before a comment became required
    product.deeplink ??
    // @ts-expect-error update CSW api
    product[`deeplink_${language}_${tenantToLocaleRegionMap[tenant]}`]
  )
}
